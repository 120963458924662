/**
 * Only put public interfaces in here
 */
export type UrlSanitizerV2 = (url: string) => string;

export interface ComponentV2 {
  componentInfo: {
    componentID: string;
    componentName?: string;
    [key: string]: unknown | undefined;
  };
  [key: string]: unknown | undefined;
}

export type UserInfoV2 = Record<string, unknown>;

// Make sure that all automatically set properties are defined as never in the TrackingEventV2!
/**
 *
 */
export interface DataLayerEventV2 {
  eventInfo: {
    eventAction: string;
    [key: string]: unknown | undefined;
  };
  attributes?: {
    relatedComponent: {
      featureAppID: string;
      featureAppName: string;
      componentIndex: number;
      [key: string]: unknown | undefined;
    };
    currentURL: string;
    [key: string]: unknown | undefined;
  };
  [key: string]: unknown | undefined;
}

export interface DataLayerV2 {
  user?: UserInfoV2;
  component?: ComponentV2[];
  event?: DataLayerEventV2[];
  [key: string]: unknown | undefined;
}

export interface ComponentAdditionsV2 {
  componentInfo?: {
    componentID?: never;
    componentName?: never;
    [key: string]: unknown | undefined;
  };
  [key: string]: unknown | undefined;
}

export type UserAdditionsV2 = Record<string, unknown>;

export interface TrackingEventV2 {
  eventInfo: {
    eventAction: string;
    [key: string]: unknown | undefined;
  };
  attributes?: {
    relatedComponent?: {
      featureAppID?: never;
      featureAppName?: never;
      componentIndex?: never;
      [key: string]: unknown | undefined;
    };
    currentURL?: never;
    [key: string]: unknown | undefined;
  };
  [key: string]: unknown | undefined;
}

/**
 * Data needed for tracking an event. Only the event itself is required.
 */
export interface TrackingDataV2 {
  /**
   * The event itself according to the tracking concept
   */
  event: TrackingEventV2;

  /**
   * Additional user information. Specifying this is equivalent to calling `trackingService.updateUser(userUpdate)`
   * before calling `track`
   */
  userUpdate?: Record<string, unknown>;

  /**
   * Additional information for the feature app's component. Specifying this is equivalent to calling
   * `trackingService.updateComponent(componentUpdate)` before calling `track`
   */
  componentUpdate?: Record<string, unknown>;
}

/**
 * Data needed for tracking an internal link
 */
export interface InternalLinkTrackingDataV2 extends TrackingDataV2 {
  /**
   * The result of `historyService.history.createHref()` with the same location that will be used for
   * `historyService.history.push()` or `.replace()`
   */
  targetHref: string;
}

export type UnregisterImpressionTrackingV2 = () => void;

/**
 * This service should be used by feature apps to create tracking events. This way the feature apps do not need to know
 * the exact implementation of tracking in the specific integrator. Still all data passed to the tracking service is
 * expected to conform with the Audi tracking concept (i.e. the data structures can be copied directly from your
 * tracking concept/requirements).
 *
 * ## Data sanitization
 *
 * Because of data privacy reasons, no personally identifiable information (PII) may be tracked and thus it may also
 * not be handed over to the tracking service. In general the calling feature app is responsible for tracking "clean"
 * events, but in some cases the feature app won't know that there is PII in the data. This is true for example for the
 * currentURL and for the targetURL when the feature app uses the history service (which results in a new current URL).
 * For these two cases the tracking service will automatically add the respective properties to the events and remove
 * any PII using a method that the integrator provided.
 *
 * ## Merge strategy
 *
 * Whenever data is added to the datalayer, it is merged with the existing data. There the first level of the affected
 * objects are merged. When two arrays should be merged, the original is completely replaced by the updated array. No
 * concatenation will take place. When the data types of a property in the original and the update don't match, then
 * the original property will be replaced with the update. No merging happens in this case.
 *
 * Example:
 * ```
 * const original = {
 *   object1: {
 *     prop1: 'test',
 *     prop2: 123,
 *     prop3: [1, 2, 3],
 *     prop4: {
 *        prop41: 'This level will not be merged'
 *     }
 *   },
 *   array1: ['a', 'b', 'c'],
 *   array2: []
 * }
 *
 * const update = {
 *   object1: {
 *     prop1: 'new value',
 *     prop3: [7, 8, 9],
 *     prop4: {
 *        propX: 'This is from the update'
 *     },
 *     prop5: 'Additional property'
 *   },
 *   array1: ['x', 'y', 'z'],
 *   array2: 456,
 *   additionalProp: true
 * }
 *
 * // merging the two object above according to the described strategy results in:
 * const mergeResult = {
 *  object1: {
 *     prop1: 'new value',
 *     prop2: 123,
 *     prop3: [7, 8, 9],
 *     prop4: {
 *        propX: 'This is from the update'
 *     }
 *   },
 *   array1: ['x', 'y', 'z'],
 *   array2: 456,
 *   additionalProp: true
 * }
 * ```
 */
export interface TrackingServiceV2 {
  /**
   * The feature app id distinguishes between instances of feature apps. It must differ between instances of the
   * same feature app.
   *
   * It can be set by the integrator in the `beforeCreate` callback of the featureAppLoader. If the integrator does
   * not set it, it will default to `env.featureAppId`
   */
  featureAppId: string;

  /**
   * The feature app name distinguishes between feature apps. It should be the same for all instances of the
   * same feature app. In must be set by the feature app as soon as it receives the feature service from the
   * environment in its create method.
   *
   * If no component exists for the feature app, it will be created upon setting the featureAppName.
   */
  featureAppName?: string;

  /**
   * Sends a tracking event to the datalayer.
   *
   * If data.event.eventAction is null or undefined, the event is dropped, i.e. it will not be written to the datalayer.
   * The componentUpdate and userUpdate will always be executed, even if the event is dropped.
   *
   * The following properties will be added to the passed event before it is written to the datalayer:
   *  - event.attributes.currentUrl
   *  - event.attributes.relatedComponent.featureAppID
   *  - event.attributes.relatedComponent.featureAppName
   *  - event.attributes.relatedComponent.componentIndex
   *
   * The currentURL will be sanitized according to the method provided by the integrator.
   *
   * This method throws an error if the feature app's component does not exist. Setting
   * `trackingService.featureAppName` ensures the existence of the component.
   *
   * @param data all data that needs to be provided to track the event.
   * @throws if the feature app's component does not exist
   */
  track(data: TrackingDataV2): void;

  /**
   * To be used when the next action is `historyService.history.push` or `historyService.history.replace`.
   *
   * The following properties will be added to the passed event before the data is handed over to the `track` method (
   * where additional data will be added):
   *  - event.attributes.targetURL
   *
   * The generated targetURL will be absolute and sanitized according to the method provided by the integrator.
   *
   * This method throws an error if the feature app's component does not exist. Setting
   * `trackingService.featureAppName` ensures the existence of the component.
   *
   * Example:
   * ```
   * const newLocation = {
   *   pathname: '/new/url',
   *   search: '?some=param'
   * };
   *
   * trackingService.trackInternalNavigation({
   *   event: {
   *     eventInfo: {
   *       eventAction: 'internal_link'
   *     },
   *     attributes: {
   *       label: 'Next',
   *       componentName: 'gallery',
   *       elementName: 'button'
   *     }
   *   },
   *   targetHref: historyService.createHref(newLocation);
   * });
   *
   * historyService.push(newLocation);
   * ```
   *
   * @param data all data that needs to be provided to track the event
   * @throws if the feature app's component does not exist
   */
  trackInternalNavigation(data: InternalLinkTrackingDataV2): void;

  /**
   * Can be used to track when a feature app or a part of it is visible to the user.
   *
   * The tracking only triggers if the feature app is visible for at least 1 second and is either entirely visible or at least 1px of it is visible in the upper 70% of the viewport.
   * For that to work the function needs the HTML DOM element that has to be tracked. Also a callback function that returns the tracking data.
   * This will be passed to trackingService.track when the impression is created.
   * The tracking of an impresion only happens once per element.
   *
   * The method returns an unregister function. After it is called, no further impression will be tracked for the given element.
   *
   * This method throws an error if the feature app's component does not exist or if the given element is already registered. Setting
   * `trackingService.featureAppName` ensures the existence of the component.
   *
   * Example:
   * ```
   *
   * dataCallback = () => {
   *  return {
   *    event: {
   *      eventInfo: {
   *        eventAction: 'impression',
   *      }
   *    }
   *  };
   * }
   * trackingService.registerImpressionTracking(featureAppElement, dataCallback);
   *
   * ```
   *
   * @param element the element used to create impressions
   * @param dataCallback function that will be called during tracking and return the trackingdata
   * @returns returns a cleanup function to remove all observers
   * @throws if the feature app's component does not exist or if the given element is already registered
   */
  registerImpressionTracking(
    element: Element,
    dataCallback: () => TrackingDataV2
  ): UnregisterImpressionTrackingV2;

  /**
   * Updates the feature app's component in the component array of the datalayer.
   *
   * The update will be merged into the the existing component according to the merge strategy defined above.
   * The following properties cannot be changed using this method:
   *   - componentInfo.componentID
   *   - componentInfo.componentName
   *
   * This method throws an error if the feature app's component does not exist. Setting
   * `trackingService.featureAppName` ensures the existence of the component.
   *
   * @param componentUpdate the data to be merged into the feature app's component.
   * @throws if the feature app's component does not exist
   */
  updateComponent(componentUpdate: ComponentAdditionsV2): void;

  /**
   * Updates the user information in the datalayer.
   *
   * The update will be merged into the the existing user object according to the merge strategy defined above.
   *
   * @param userUpdate the data to be merged into the user information in the datalayer.
   */
  updateUser(userUpdate: UserAdditionsV2): void;

  /**
   * Return correct event action for internal or external link for the given targetUrl to be used in
   * event.eventInfo.eventAction.
   *
   * If `targetUrl` begins with, will return:
   *   - / (relative URL) => internal link
   *   - http(s) (absolute URL) => compare targetUrl domain with current domain
   *     - if it's the same domain => internal link
   *     - if it's a different domain => external link
   *   - # (anchor link) => undefined
   *   - anything else (includes targetURL not present, empty string or relative URLs without leading /) => undefined
   *
   * If undefined is returned, either a different eventAction must be used or the event must not be tracked.
   *
   * @param targetUrl URL which will be used in subsequent `track` call
   */
  evaluateLinkType(targetUrl: string): string | undefined;

  /**
   * Provides the full data layer to the feature app to allow for complex modifications.
   *
   * This is the ultimate escape hatch and should not normally be used!
   */
  getDatalayer(): DataLayerV2;

  /**
   * A function that removes all personally identifiable information (PII) from the given URL.
   * That the integrator knows about. Examples will include VINs.
   *
   * @param url URL which contains PII
   */
  sanitizeUrl(url: string): string;
}
